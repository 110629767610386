(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Coveo"));
	else if(typeof define === 'function' && define.amd)
		define(["Coveo"], factory);
	else if(typeof exports === 'object')
		exports["CoveoExtension"] = factory(require("Coveo"));
	else
		root["CoveoExtension"] = factory(root["Coveo"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__845__) => {
return 