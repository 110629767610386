"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.AzFilter = void 0;
var coveo_search_ui_1 = require("coveo-search-ui");
var AzFilter = /** @class */ (function (_super) {
    __extends(AzFilter, _super);
    function AzFilter(element, options, bindings) {
        var _this = _super.call(this, element, AzFilter.ID, bindings) || this;
        _this.element = element;
        _this.options = options;
        _this.bindings = bindings;
        _this.letters = [
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
        ];
        _this.options = coveo_search_ui_1.ComponentOptions.initComponentOptions(element, AzFilter, options);
        _this.initComponent(element);
        _this.initQueryStateEvents();
        _this.initBreadCrumbEvents();
        _this.bind.onRootElement(coveo_search_ui_1.QueryEvents.buildingQuery, function (args) { return _this.handleBuildingQuery(args); });
        _this.bind.onRootElement('state:change:' + _this.getFacetField(), function (args) { return _this.handleQueryStateChanged(args); });
        return _this;
    }
    AzFilter.prototype.initComponent = function (element) {
        var _this = this;
        // created ordered list for letters
        this.list = (0, coveo_search_ui_1.$$)('ol', {
            className: 'alpha-filters'
        }).el;
        // append ordered list to root element
        element.appendChild(this.list);
        var _loop_1 = function () {
            var filter = (0, coveo_search_ui_1.$$)('li', {
                className: 'alpha-filters__filter'
            });
            filter.el.appendChild((0, coveo_search_ui_1.$$)('button', {
                className: 'alpha-filters__letter',
                type: 'button'
            }, this_1.letters[i]).el);
            // map click to button
            (function (letter) {
                var clickAction = function () { return _this.handleLetterClick(letter); };
                filter.on('click', clickAction);
                filter.on('keyup', coveo_search_ui_1.KeyboardUtils.keypressAction(coveo_search_ui_1.KEYBOARD.ENTER, clickAction));
            })(this_1.letters[i]);
            this_1.list.appendChild(filter.el);
        };
        var this_1 = this;
        // for every letter, create an li and add an anchor with the letter at text
        for (var i = 0; i < this.letters.length; i++) {
            _loop_1();
        }
    };
    AzFilter.prototype.initQueryStateEvents = function () {
        this.queryStateModel.registerNewAttribute(this.getFacetField(), '');
    };
    AzFilter.prototype.initBreadCrumbEvents = function () {
        var _this = this;
        this.bind.onRootElement(coveo_search_ui_1.BreadcrumbEvents.populateBreadcrumb, function (args) {
            return _this.handlePopulateBreadcrumb(args);
        });
        this.bind.onRootElement(coveo_search_ui_1.BreadcrumbEvents.clearBreadcrumb, function (args) { return _this.clearLetterFilter(); });
    };
    AzFilter.prototype.handlePopulateBreadcrumb = function (args) {
        var _this = this;
        if (this.selectedLetter) {
            var addedElement = void 0;
            addedElement = (0, coveo_search_ui_1.$$)('span', {
                className: 'coveo-facet-breadcrumb-value coveo-selected coveo-selected',
            }, this.selectedLetter);
            var clear = (0, coveo_search_ui_1.$$)('span', {
                className: 'coveo-facet-breadcrumb-clear'
            }, '<svg enable-background="new 0 0 11 11" viewBox="0 0 11 11" xmlns="http://www.w3.org/2000/svg" class="coveo-facet-breadcrumb-clear-svg"><g class="coveo-more-svg" fill="none"><path d="m10.083 4.583h-3.666v-3.666c0-.524-.393-.917-.917-.917s-.917.393-.917.917v3.667h-3.666c-.524-.001-.917.392-.917.916s.393.917.917.917h3.667v3.667c-.001.523.392.916.916.916s.917-.393.917-.917v-3.666h3.667c.523 0 .916-.393.916-.917-.001-.524-.394-.917-.917-.917z"></path></g><g class="coveo-line-svg" fill="none"><path d="m10 6.5h-9c-.552 0-1-.448-1-1 0-.552.448-1 1-1h9c .552 0 1 .448 1 1 0 .552-.448 1-1 1z"></path></g><g class="coveo-exclusion-svg" fill="none"><path d="m9.233 7.989-2.489-2.489 2.489-2.489c.356-.356.356-.889 0-1.244-.356-.356-.889-.356-1.244 0l-2.489 2.489-2.489-2.489c-.356-.356-.889-.356-1.244 0-.356.356-.356.889 0 1.244l2.489 2.489-2.489 2.489c-.356.356-.356.889 0 1.244.356.356.889.356 1.244 0l2.489-2.489 2.489 2.489c.356.356.889.356 1.244 0 .356-.355.356-.889 0-1.244z"></path></g><g class="coveo-hook-svg" fill="none"><path d="m10.252 2.213c-.155-.142-.354-.211-.573-.213-.215.005-.414.091-.561.24l-4.873 4.932-2.39-2.19c-.154-.144-.385-.214-.57-.214-.214.004-.415.09-.563.24-.148.147-.227.343-.222.549.005.207.093.4.249.542l2.905 2.662c.168.154.388.239.618.239h.022.003c.237-.007.457-.101.618-.266l5.362-5.428c.148-.148.228-.344.223-.551s-.093-.399-.248-.542z"></path></g></svg>');
            addedElement.el.appendChild(clear.el);
            addedElement.on('click', function () {
                _this.clearLetterFilter();
            });
            args.breadcrumbs.push({ element: addedElement.el });
        }
    };
    AzFilter.prototype.handleQueryStateChanged = function (args) {
        if (args && args.value) {
            this.selectedLetter = args.value;
            this.queryController.executeQuery();
        }
    };
    AzFilter.prototype.updateQueryState = function () {
        this.queryStateModel.set(this.getFacetField(), this.selectedLetter);
    };
    AzFilter.prototype.clearLetterFromQueryState = function () {
        this.queryStateModel.set(this.getFacetField(), undefined);
    };
    AzFilter.prototype.handleBuildingQuery = function (args) {
        if (this.selectedLetter) {
            this.updateQueryState();
            args.queryBuilder.advancedExpression.addFieldExpression(this.options.field.toString(), '==', [this.selectedLetter]);
        }
    };
    AzFilter.prototype.handleLetterClick = function (letter) {
        this.selectedLetter = letter;
        this.queryController.executeQuery();
    };
    AzFilter.prototype.clearLetterFilter = function () {
        this.selectedLetter = undefined;
        this.clearLetterFromQueryState();
        this.queryController.executeQuery();
    };
    AzFilter.prototype.getFacetField = function () {
        return 'f:' + this.options.field.toString();
    };
    AzFilter.ID = 'AzFilter';
    AzFilter.options = {
        field: coveo_search_ui_1.ComponentOptions.buildFieldOption({ required: true })
    };
    return AzFilter;
}(coveo_search_ui_1.Component));
exports.AzFilter = AzFilter;
coveo_search_ui_1.Initialization.registerAutoCreateComponent(AzFilter);
