"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.DropdownFacet = void 0;
var coveo_search_ui_1 = require("coveo-search-ui");
var DropdownFacet = /** @class */ (function (_super) {
    __extends(DropdownFacet, _super);
    function DropdownFacet(element, options, bindings) {
        var _this = _super.call(this, element, DropdownFacet.ID, bindings) || this;
        _this.element = element;
        _this.options = options;
        _this.bindings = bindings;
        _this.options = coveo_search_ui_1.ComponentOptions.initComponentOptions(element, DropdownFacet, options);
        _this.usageAnalytics = bindings.usageAnalytics || _this.resolveUA();
        _this.initComponent(element);
        _this.initQueryStateEvents();
        _this.bind.onRootElement(coveo_search_ui_1.QueryEvents.buildingQuery, function (args) { return _this.handleBuildingQuery(args); });
        _this.bind.onRootElement(coveo_search_ui_1.QueryEvents.querySuccess, function (args) { return _this.handleQuerySuccess(args); });
        _this.bind.onRootElement('state:change:' + _this.getFacetField(), function (args) { return _this.handleQueryStateChanged(args); });
        return _this;
    }
    // Initization functions
    DropdownFacet.prototype.initComponent = function (element) {
        this.container = (0, coveo_search_ui_1.$$)('div', {
            className: 'pretty-dropdown'
        }).el;
        element.appendChild(this.container);
    };
    DropdownFacet.prototype.initQueryStateEvents = function () {
        this.queryStateModel.registerNewAttribute(this.getFacetField(), '');
    };
    // HTML Building functions
    DropdownFacet.prototype.buildDropdown = function (options) {
        var _this = this;
        var select;
        var titleLower = this.options.title.toLowerCase();
        select = (0, coveo_search_ui_1.$$)('select', {
            className: 'pretty-dropdown__select',
            id: titleLower,
            ariaLabel: 'Filter Criteria',
            value: ''
        });
        select.on('change', function (e) {
            var target;
            target = e.target;
            _this.dropdownLabel.innerHTML = target.value;
            _this.handleOptionClick(target.value);
        });
        if (options && options.length > 0) {
            select.el.appendChild((0, coveo_search_ui_1.$$)('option', {
                value: '',
                disabled: 'disabled'
            }, 'Select Option').el);
            for (var i = 0; i < options.length; i++) {
                var optionText = options[i];
                select.el.appendChild((0, coveo_search_ui_1.$$)('option', {
                    value: optionText
                }, optionText).el);
            }
        }
        else {
            console.debug('No valid dropdown options are available.');
        }
        this.container.appendChild(select.el);
        this.dropdownLabel = (0, coveo_search_ui_1.$$)('label', {
            className: 'pretty-dropdown__label',
            for: titleLower
        }, !!this.currentFacetValue ? this.currentFacetValue : this.options.title).el;
        this.container.appendChild(this.dropdownLabel);
    };
    DropdownFacet.prototype.rebuildDropdown = function (options) {
        this.container.innerHTML = '';
        this.buildDropdown(options);
    };
    // Handler functions
    DropdownFacet.prototype.handleQueryStateChanged = function (args) {
        if (args && args.value) {
            this.currentFacetValue = args.value;
            this.queryController.executeQuery();
        }
    };
    DropdownFacet.prototype.handleBuildingQuery = function (args) {
        if (this.currentFacetValue) {
            this.updateQueryState();
            args.queryBuilder.advancedExpression.addFieldExpression(this.options.field.toString(), '==', [this.currentFacetValue]);
        }
        var request = {
            field: this.options.field,
            maximumNumberOfValues: 10,
            allowedValues: undefined
        };
        args.queryBuilder.groupByRequests.push(request);
    };
    DropdownFacet.prototype.handleQuerySuccess = function (args) {
        this.rebuildDropdown(this.filterDropdownOptions(args.results.groupByResults));
    };
    DropdownFacet.prototype.handleOptionClick = function (facetValue) {
        var _this = this;
        this.currentFacetValue = facetValue;
        this.triggerNewQuery(function () {
            return _this.usageAnalytics.logSearchEvent(coveo_search_ui_1.analyticsActionCauseList.facetSelect, {
                facetField: _this.options.field.toString(),
                facetId: DropdownFacet.ID,
                facetTitle: _this.options.title,
                facetValue: _this.currentFacetValue
            });
        });
    };
    // Utility functions
    DropdownFacet.prototype.updateQueryState = function () {
        this.queryStateModel.set(this.getFacetField(), this.currentFacetValue);
    };
    DropdownFacet.prototype.filterDropdownOptions = function (groupByResults) {
        var _this = this;
        var fieldSpecificResults = groupByResults.filter(function (r) { return r.field === _this.options.field.slice(1); });
        if (!fieldSpecificResults || fieldSpecificResults.length === 0) {
            console.error('Group by Results do not match configured field.');
            return;
        }
        if (!fieldSpecificResults[0].values || fieldSpecificResults[0].values.length === 0) {
            console.debug('No valid options are available for ' + this.options.field);
            return;
        }
        // retrieve all options available after splitting on semicolon
        var validOptionTable = fieldSpecificResults[0].values.map(function (obj) { return obj.value.split(';'); })
            // reduce all arrays into a single array with duplicates of options
            .reduce(function (all, optionArray) { return all.concat(optionArray); }, [])
            // de-duplicate the option array
            .reduce(function (unique, option) { return unique.indexOf(option) === -1 ? unique.concat([option]) : unique; }, []).sort()
            // create a hash table for quick look up
            .reduce(function (table, option) { table[option] = true; return table; }, {});
        return this.options.dropdownOptions.filter(function (option) { return validOptionTable[option]; });
    };
    DropdownFacet.prototype.getFacetField = function () {
        return 'f:' + this.options.field.toString();
    };
    DropdownFacet.prototype.triggerNewQuery = function (beforeExecuteQuery) {
        if (!beforeExecuteQuery) {
            this.queryController.executeQuery({ ignoreWarningSearchEvent: true });
        }
        else {
            this.queryController.executeQuery({ beforeExecuteQuery: beforeExecuteQuery });
        }
    };
    DropdownFacet.ID = 'DropdownFacet';
    DropdownFacet.options = {
        field: coveo_search_ui_1.ComponentOptions.buildFieldOption({ required: true }),
        title: coveo_search_ui_1.ComponentOptions.buildStringOption({ defaultValue: 'Select Option' }),
        dropdownOptions: coveo_search_ui_1.ComponentOptions.buildListOption()
    };
    return DropdownFacet;
}(coveo_search_ui_1.Component));
exports.DropdownFacet = DropdownFacet;
coveo_search_ui_1.Initialization.registerAutoCreateComponent(DropdownFacet);
